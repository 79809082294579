<template>
  <Header :currentIndex="0" />
  <div class="wrapper">
    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content">
          {{ $t("header_menu.FIRMWARE") }}
        </span>
        <hr class="firmtitle__hr" />
      </div>
      <div class="midcontent">
        <div class="midcontent__title">CURRENTLY INSTALLED ON</div>
        <div class="midcontent__title2">YOUR DEVICE:</div>
      </div>

      <div class="vermg">
        <div class="vermg__vermgtitle">
          <span class="vermg__vermgtitle__content"> SYSTEM FIRMWARE:</span>
          <span
            class="vermg__vermgtitle__FIRMWARE"
            :style="{
              color:
                $store.state.dataList.tsetting.isnew != 'NEW!'
                  ? '#00FFFF'
                  : '#FFFF19',
            }"
          >
            V{{ $store.state.dataList.tsetting.version }}
          </span>
          <span class="vermg__vermgtitle__new">
            {{ $store.state.dataList.tsetting.isnew }}
          </span>
          <hr class="vermg__hr" />
        </div>
        <div class="vermg__vermgtitle">
          <span class="vermg__vermgtitle__content"> THEME 1:RACE1</span>
          <span
            class="vermg__vermgtitle__FIRMWARE"
            :style="{
              color:
                $store.state.dataList.t1.isnew != 'NEW!'
                  ? '#00FFFF'
                  : '#FFFF19',
            }"
          >
            V{{ $store.state.dataList.t1.version }}
          </span>
          <span class="vermg__vermgtitle__new">
            {{ $store.state.dataList.t1.isnew }}
          </span>
          <hr class="vermg__hr" />
        </div>
        <div class="vermg__vermgtitle">
          <span class="vermg__vermgtitle__content"> THEME 2:MINIMAL1</span>
          <span
            class="vermg__vermgtitle__FIRMWARE"
            :style="{
              color:
                $store.state.dataList.t2.isnew != 'NEW!'
                  ? '#00FFFF'
                  : '#FFFF19',
            }"
          >
            V{{ $store.state.dataList.t2.version }}
          </span>
          <span class="vermg__vermgtitle__new">
            {{ $store.state.dataList.t2.isnew }}
          </span>
          <hr class="vermg__hr" />
        </div>
        <div class="vermg__vermgtitle">
          <span class="vermg__vermgtitle__content"> THEME 3:Zoom1</span>
          <span
            class="vermg__vermgtitle__FIRMWARE"
            :style="{
              color:
                $store.state.dataList.t3.isnew != 'NEW!'
                  ? '#00FFFF'
                  : '#FFFF19',
            }"
          >
            V{{ $store.state.dataList.t3.version }}
          </span>
          <span class="vermg__vermgtitle__new">
            {{ $store.state.dataList.t3.isnew }}
          </span>
        </div>
      </div>

      <van-overlay :show="showconfirm" @click="showconfirm = false">
        <div class="wrapper1" @click.stop>
          <div class="block">
            <span class="block__title"> installing: </span>
            <hr class="block__hr" />
            <span class="block__msg"> upgrade all </span>
            <span class="block__msg1"> (English version) </span>
            <div class="block__confirm">
              <div class="block__confirm__cancel">
                <span
                  class="block__confirm__cancel__title"
                  @click="showconfirm = false"
                  >cancel</span
                >
              </div>

              <span class="block__confirm__y">
                <span
                  class="block__confirm__y__title"
                  @click="
                    allupdate(), (showupmsg = true), (showconfirm = false)
                  "
                  >confirm</span
                >
              </span>
            </div>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="showConnect" @click="showConnect = false">
        <div class="wrapper1" @click.stop>
          <div class="block">
            <span class="block__title"> DISCONNECT </span>
            <hr class="block__hr" />
            <span class="block__msg"> DISCONNECT </span>
            <!-- <span class="block__msg1"> (--!) </span> -->
            <div class="block__confirm">
              <div class="block__confirm__cancel">
                <span
                  class="block__confirm__cancel__title"
                  @click="showConnect = false"
                  >cancel</span
                >
              </div>

              <span class="block__confirm__y">
                <span class="block__confirm__y__title">confirm</span>
              </span>
            </div>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="showupmsg" @click="showupmsg = false">
        <div class="wrapper1" @click="showupmsg = false">
          <div class="block1">
            <span class="block1__title"> uploading: </span>
            <div class="block1__selectname">
              <span class="block1__selectname__content">s.Firmware </span>
              <span class="block1__selectname__contentname">theme 1 </span>
              <span class="block1__selectname__contentname">theme 2 </span>
              <span class="block1__selectname__contentname">theme 3 </span>
              <span class="block1__selectname__contentname">wifi </span>
            </div>
            <div class="block1__bottom">
              <span class="block1__bottom__magone"> do not shut down G2 </span>
              <span class="block1__bottom__magtwo">
                do not exit wifi mode until completion
              </span>
              <hr class="block1__bottom__hr" />
              <div class="block1__bottom__msg">
                <span class="block1__bottom__msg__magwait"> please wait, </span>
                <span class="block1__bottom__msg__magwait2">
                  while the Installation files are
                </span>
                <span class="block1__bottom__msg__magwait3">
                  being uploaded to your G2.
                </span>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>

      <div>
        <!-- @click="allupdate" -->
        <div class="but">
          <van-button
            class="updatebut iconfont"
            type="default"
            @click="
              $store.state.isconnect == 'CONNECTED'
                ? (showconfirm = true)
                : (showConnect = true)
            "
          >
            <span class="updatebut__icon">&#xe60c;</span>
            <span class="updatebut__title">UPDATE&nbsp;ALL</span>
          </van-button>
        </div>
        <div class="icon">
          <p class="iconfont supporticon" @click="showPopup">&#xed19;</p>

          <van-popup
            v-model:show="show"
            closeable
            close-icon="close"
            position="bottom"
            :style="{ height: '30%' }"
          >
            <div class="help__title">UPDATE ALL</div>
            <hr class="help__hr" />

            <div class="help__content">
              <p>Automatically update all new versions of</p>
              <p>both system firmware and current themes.</p>
              <p>your settings memory will remain untouched</p>
            </div>
          </van-popup>
        </div>
      </div>
    </div>

    <div class="twobox">
      <div class="whattitle">
        <span class="whattitle__content"> WHAT'S NEW: </span>
        <hr class="whattitle__hr" />
      </div>

      <div
        v-for="(item, index) in $store.state.dataList.up"
        :key="index"
        class="newver"
      >
        <div class="newver__num">
          <span class="newver__num__content"> {{ item.name }}</span>
          <span class="newver__num__num"> {{ item.version }} </span>
        </div>
        <div class="newver__date">
          <span class="newver__date__content"> DATE: </span>
          <span class="newver__date__time"> {{ item.createtime }}</span>
        </div>

        <div class="newver__content">
          <span class="newver__content__what"> WHAT'S NEW: </span>
          <!-- <span class="newver__content__content" v-html="item.content"></span> -->
          <span v-html="item.content"></span>
        </div>
        <hr class="newver__hr" />
      </div>
    </div>
    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>

  <!-- <Idfoot /> -->
</template>

<script>
// import Idfoot from "../../components/Idfoot.vue";
import Header from "../../components/Header.vue";
import { ref, reactive } from "vue";
import { post } from "../../utils/request";
import { useStore } from "vuex";
// import { Dialog } from "vant";
// import { useI18n } from "vue-i18n";

const alluploadEffect = () => {
  // const { t } = useI18n();
  const store = useStore();
  const getversionlist = async () => {
    console.log("sessionStorage.theme3:", store.state.initData.theme3);
    try {
      const result1 = await post("getupversionlist", {
        t1: sessionStorage.theme1,
        t2: sessionStorage.theme2,
        t3: sessionStorage.theme3,
        setting: sessionStorage.setting,
        token: sessionStorage.token,
      });

      if (result1?.errno === 0) {
        console.log(result1);
        // sessionStorage.setItem("data", JSON.stringify(result1));
        store.commit("changeDatalist", { result1 });
        console.log("getupversionlist:", store.state.dataList);
      } else {
        console.log("err", result1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //获取37号仓内容
  const getfleshstorge = async () => {
    try {
      const result = await post("fleshstorge", {
        token: sessionStorage.token,
      });

      if (result?.errno === 0) {
        sessionStorage.setting = result?.list.setting;
        sessionStorage.theme1 = result?.list.theme1;
        sessionStorage.theme2 = result?.list.theme2;
        sessionStorage.theme3 = result?.list.theme3;
        sessionStorage.wifi = result?.list.wifi;

        const setinitData = {
          setting: result?.list.setting,
          theme1: result?.list.theme1,
          theme2: result?.list.theme2,
          theme3: result?.list.theme3,
          wifi: result?.list.wifi,
        };

        store.commit("changeInitData", { setinitData });

        console.log("getfleshstorge.theme3:", store.state.initData.theme3);
      } else {
        console.log("err", result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const allupdate = () => {
    // const data = JSON.parse(sessionStorage.getItem("data"));
    // console.log('data.isuplist',data.isuplist)

    upClick(store.state.dataList.isuplist);

    /*确认按钮组件调用
    // const isuplist =  data.isuplist.toString();
    // console.log('data.isuplist',isuplist)
    Dialog.confirm({
      title: t("updateconfirm.title"),
      message: t("updateconfirm.message"),
      confirmButtonText: t("updateconfirm.confirmButtonText"),
      cancelButtonText: t("updateconfirm.cancelButtonText"),
    })
      .then(() => {
        upClick(data.isuplist);
        console.log("OK");
      })
      .catch(() => {
        console.log("***");
      });*/
  };
  const upClick = async (uplist) => {
    console.log('uplist',uplist)
    try {
      const result = await post("upallnew", {
        uplist: uplist,
        token: sessionStorage.token,
      });
      console.log("upClick", result);
      if (result?.errno === 0) {
        //一分钟后刷新

        setTimeout(() => {
          getfleshstorge();
        }, 30000);

        setTimeout(() => {
          getversionlist();
        }, 35000);

        console.log(result);
      } else {
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return { allupdate, upClick, getfleshstorge, getversionlist };
};

export default {
  name: "firmware",
  components: { Header },

  setup() {
    const { allupdate, getfleshstorge, getversionlist } = alluploadEffect();
    getfleshstorge();
    getversionlist();
    const store = useStore();
    // const data = JSON.parse(sessionStorage.getItem("data"));
    // console.log(data);
    const dataList = reactive(store.state.dataList);
    const show = ref(false);
    const showconfirm = ref(false);
    const showupmsg = ref(false);
    const showConnect = ref(false);

    const showPopup = () => {
      show.value = true;
    };
    return {
      show,
      showPopup,
      allupdate,
      getfleshstorge,
      getversionlist,
      showconfirm,
      showupmsg,
      dataList,
      showConnect,
    };
  },
};
</script>
<style  lang="scss" scoped>
@import "../../style/750/firmware.scss";
.wrapper1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block1 {
  width: 640px;
  height: 943px;
  background: #ffffff;
  border: 2px solid #707070;
  opacity: 1;
  border-radius: 82px;

  &__title {
    display: inline-block;
    margin-left: 200px;
    margin-top: 70px;
    width: 247px;
    height: 50px;
    font-size: 70px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }

  &__selectname {
    display: block;
    width: 636px;
    height: 389px;
    background: #808080;
    border: 2px solid #707070;
    opacity: 1;
    border-radius: 0px;

    &__content {
      display: inline-block;
      margin-left: 186px;
      margin-top: 50px;
      width: 263px;
      height: 50px;
      font-size: 70px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }

    &__contentname {
      display: inline-block;
      margin-left: 186px;
      margin-top: 20px;
      width: 263px;
      height: 50px;
      font-size: 70px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }

  &__bottom {
    margin-top: 51px;

    &__magone {
      display: inline-block;
      margin-left: 81px;
      width: 475px;
      height: 50px;
      font-size: 70px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__magtwo {
      display: inline-block;
      margin-left: 37px;
      width: 561px;
      height: 40px;
      font-size: 44px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__hr {
      width: 475px;
      height: 0px;
      border: 2px solid #707070;
      opacity: 1;
    }
    &__msg {
      width: 557px;
      height: 189px;
      font-size: 53px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #161616;
      opacity: 1;

      &__magwait {
        display: inline-block;
        margin-left: 200px;
        margin-top: 30px;
        height: 30px;
      }
      &__magwait2 {
        display: inline-block;
        margin-left: 37px;
        width: 600px;
        margin-top: 30px;
        font-family: BebasNeue;
        height: 30px;
      }
      &__magwait3 {
        display: inline-block;
        height: 30px;
        margin-top: 30px;
        width: 100%;
        margin-left: 83px;
      }
    }
  }
}

.block {
  width: 640px;
  height: 519px;
  background: #ffffff;
  border: 2px solid #707070;
  opacity: 1;
  border-radius: 82px;
  font-family: BebasNeue;

  &__confirm {
    width: 640px;
    display: inline-block;
    margin-top: 110px;

    &__cancel {
      display: inline-block;
      width: 50%;
      &__title {
        width: 161px;
        height: 84px;
        font-size: 70px;
        margin-left: 58px;
        font-family: BebasNeue;
        font-weight: 400;
        line-height: 0px;
        color: #161616;
        opacity: 1;
      }
    }
    &__y {
      width: 50%;
      &__title {
        width: 161px;
        height: 84px;
        font-size: 70px;
        margin-left: 65px;
        font-family: BebasNeue;
        font-weight: 400;
        line-height: 0px;
        color: #161616;
        opacity: 1;
      }
    }
  }

  &__msg {
    display: inline-block;
    width: 280px;
    height: 50px;
    margin-left: 180px;
    margin-top: 70px;
    font-size: 70px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }
  &__msg1 {
    display: inline-block;
    width: 500px;
    height: 54px;
    margin-left: 200px;
    font-size: 44px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }

  &__title {
    display: inline-block;
    margin-left: 200px;
    margin-top: 70px;
    width: 247px;
    height: 40px;
    font-size: 70px;

    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }
}
</style>
<style  lang="scss" >
:root {
  --van-dialog-width: 580px;
  --van-dialog-font-size: 40px;
  --van-dialog-message-font-size: 38px;
  --van-dialog-header-line-height: 100px;
  --van-dialog-message-line-height: 50px;
  --van-dialog-button-height: 100px;
  --van-dialog-message-max-height: 200px;
  --van-button-default-font-size: 38px;
}
</style>
