<template>
  <div class="header">
    <div class="header__banner">
      <img
        class="header__banner__img"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/G2.png"
      />
      <img
        class="header__banner__sxmini"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/sxmini.png"
      />
      <img
        class="header__banner__yh"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/yh.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Headerpanel",
};
</script>

<style lang="scss" scoped>
@import "../style/viriables.scss";

.header {
    overflow-x: hidden;
    height: 268px;
    background: #000000;
    opacity: 1;
    font-family: BebasNeue;
    font-size: 16px;
    width: 750px;

    &__banner {
      overflow: hidden; 
      height: 268px;
      background: url(../assets/Gclassbanner.png) no-repeat center bottom,
        linear-gradient(-225deg, #5d9fff 0%, #b8dcff 48%, #131313 100%);
     

      &__img {
        display: inline-block;
        width: 107px;
        margin-top: 10px;
        padding-bottom: 14px;
        margin-left: 14px;
      }

      &__sxmini {
        display: inline-block;
        text-align: left;
        width: 186px;

        position: absolute;
        top: 0;
        margin-top: 15px;
        margin-left: 443px;
        margin-bottom: 22px;
        height: 47px;
      }

      &__yh {
        display: inline-block;
        position: absolute;
        top: 0;
        margin-top: 84px;
        text-align: left;
        width: 68px;
        padding-bottom: 40px;
        margin-left: 547px;
        height: 47px;
      }

      &__img2 {
        width: 750px;
        height: 251px;
        opacity: 1;
        border-radius: 0px;
      }
      &__img3 {
        width: 750px;
        height: 278px;
        opacity: 1;
        border-radius: 0px;
      }
    }
  }


</style>
