<template>
  <Header :currentIndex="2" />
  <div class="wrapper">
    <div class="onebox">
      <div class="imagetitle">
        <span class="imagetitle__content">{{
          $t("header_menu.WALLPAPER")
        }}</span>
        <hr class="imagetitle__hr" />
      </div>

      <div class="upimg">
        <div class="iconfont iconpoint">&#xe615;</div>
        <div class="titlepoint">
          <span class="titlepoint__spanone">supported files:</span><br />
          <span class="titlepoint__spantwo">PNG/JPG format | up to 500KB</span>
        </div>

        <p class="iconfont pointicon" @click="showPopup">&#xed19;</p>
        <van-popup
          v-model:show="show1"
          closeable
          close-icon="close"
          position="bottom"
          :style="{ height: '221px' }"
        >
          <div class="help__title">WALLPAPER</div>
          <div class="help__content">
            <p>View wallpapers on themes 2 and 3.</p>
            <p>joystick Left to switch between images.</p>
            view wallpaper ID# on g2 device
          </div>
        </van-popup>
      </div>

      <div class="imageupbutton">
        <el-upload
          class="upload-demo"
          ref="upload1"
          action="https://image.yihisxminiid.com/api/upbin/upimg"
          :on-preview="handlePreview"
          :on-success="handlesuccess"
          :auto-upload="false"
          :before-upload="
            (file) => {
              handlebefore(file, 1);
            }
          "
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 1);
            }
          "
          :show-file-list="false"
          :data="token"
        >
          <i
            v-if="imageUrl_1"
            class="iconfont iconclose"
            @click="handleRemove(1)"
            >&#xe6f2;</i
          >
          <template #trigger class="imageuplist">
            <div v-if="imageUrl_1">
              <img :src="imageUrl_1" class="avatar" />

              <!-- <i class="el-icon-close iconclose"></i> -->
            </div>
            <i v-else class="avatartobe">
              <img
                class="avatariconimg"
                src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/icon/open.png"
              />
              <span class="avataricontitle">SELECT</span>
            </i>
          </template>
          <el-button class="imageupload" size="small" @click="submitUpload(1)">
            <span class="replacestyle">
              <span class="replacetitle">REPLACE</span>
              <span class="iconfont replaceicon">&#xe60f;</span>
            </span>
            <span class="wallpapernum"
              >WALLPAPER <span style="color: #20ff19">#1</span></span
            >
          </el-button>
        </el-upload>
      </div>

      <div class="imageupbutton">
        <el-upload
          class="upload-demo"
          ref="upload2"
          action="https://image.yihisxminiid.com/api/upbin/upimg"
          :on-success="handlesuccess"
          :auto-upload="false"
          :before-upload="
            (file) => {
              handlebefore(file, 2);
            }
          "
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 2);
            }
          "
          :show-file-list="false"
          :data="token"
        >
          <i
            v-if="imageUrl_2"
            class="iconfont iconclose"
            @click="handleRemove(2)"
            >&#xe6f2;</i
          >
          <template #trigger class="imageuplist">
            <div v-if="imageUrl_2">
              <img :src="imageUrl_2" class="avatar" />
            </div>
            <i v-else class="avatartobe">
              <img
                class="avatariconimg"
                src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/icon/open.png"
              />
              <span class="avataricontitle">SELECT</span>
            </i>
          </template>
          <el-button class="imageupload" size="small" @click="submitUpload(2)">
            <span class="replacestyle">
              <span class="replacetitle">REPLACE</span>
              <span class="iconfont replaceicon">&#xe60f;</span>
            </span>
            <span class="wallpapernum"
              >WALLPAPER <span style="color: #20ff19">#2</span></span
            >
          </el-button>
        </el-upload>
      </div>

      <div class="imageupbutton">
        <el-upload
          class="upload-demo"
          ref="upload3"
          action="https://image.yihisxminiid.com/api/upbin/upimg"
          :on-success="handlesuccess"
          :auto-upload="false"
          :before-upload="
            (file) => {
              handlebefore(file, 3);
            }
          "
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 3);
            }
          "
          :show-file-list="false"
          :data="token"
        >
          <i
            v-if="imageUrl_3"
            class="iconfont iconclose"
            @click="handleRemove(3)"
            >&#xe6f2;</i
          >
          <template #trigger class="imageuplist">
            <div v-if="imageUrl_3">
              <img :src="imageUrl_3" class="avatar" />
            </div>
            <i v-else class="avatartobe">
              <img
                class="avatariconimg"
                src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/icon/open.png"
              />
              <span class="avataricontitle">SELECT</span>
            </i>
          </template>
          <el-button class="imageupload" size="small" @click="submitUpload(3)">
            <span class="replacestyle">
              <span class="replacetitle">REPLACE</span>
              <span class="iconfont replaceicon">&#xe60f;</span>
            </span>
            <span class="wallpapernum"
              >WALLPAPER <span style="color: #20ff19">#3</span></span
            >
          </el-button>
        </el-upload>
      </div>

      <div class="imageupbutton">
        <el-upload
          class="upload-demo"
          ref="upload4"
          action="https://image.yihisxminiid.com/api/upbin/upimg"
          :on-success="handlesuccess"
          :auto-upload="false"
          :before-upload="
            (file) => {
              handlebefore(file, 4);
            }
          "
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 4);
            }
          "
          :show-file-list="false"
          :data="token"
        >
          <i
            v-if="imageUrl_4"
            class="iconfont iconclose"
            @click="handleRemove(4)"
            >&#xe6f2;</i
          >
          <template #trigger class="imageuplist">
            <div v-if="imageUrl_4">
              <img :src="imageUrl_4" class="avatar" />
            </div>
            <i v-else class="avatartobe">
              <img
                class="avatariconimg"
                src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/icon/open.png"
              />
              <span class="avataricontitle">SELECT</span>
            </i>
          </template>
          <el-button class="imageupload" size="small" @click="submitUpload(4)">
            <span class="replacestyle">
              <span class="replacetitle">REPLACE</span>
              <span class="iconfont replaceicon">&#xe60f;</span>
            </span>
            <span class="wallpapernum"
              >WALLPAPER <span style="color: #20ff19">#4</span></span
            >
          </el-button>
        </el-upload>
      </div>

      <div class="imageupbutton">
        <el-upload
          class="upload-demo"
          ref="upload5"
          action="https://image.yihisxminiid.com/api/upbin/upimg"
          :on-success="handlesuccess"
          :before-upload="
            (file) => {
              handlebefore(file, 5);
            }
          "
          :auto-upload="false"
          :on-change="
            (file, fileList) => {
              handleChange(file, fileList, 5);
            }
          "
          :show-file-list="false"
          :data="token"
        >
          <i
            v-if="imageUrl_5"
            class="iconfont iconclose"
            @click="handleRemove(5)"
            >&#xe6f2;</i
          >
          <template #trigger class="imageuplist">
            <div v-if="imageUrl_5">
              <img :src="imageUrl_5" class="avatar" />
            </div>
            <i v-else class="avatartobe">
              <img
                class="avatariconimg"
                src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/icon/open.png"
              />
              <span class="avataricontitle">SELECT</span>
            </i>
          </template>

          <van-overlay :show="show" @click="show = false">
            <div class="wrapper1" @click="show = false">
              <div class="block">
                <span class="block__title"> uploading: </span>
                <div class="block__selectname">
                  <span class="block__selectname__content">wallpaper #{{waptitle}} </span>
                </div>
                <div class="block__bottom">
                  <span class="block__bottom__magone">
                    do not shut down G2
                  </span>
                  <span class="block__bottom__magtwo">
                    do not exit wifi mode until completion
                  </span>
                  <hr class="block__bottom__hr" />
                  <div class="block__bottom__msg">
                    <span class="block__bottom__msg__magwait">
                      please wait,
                    </span>
                    <span class="block__bottom__msg__magwait2">
                      while the Installation files are
                    </span>
                    <span class="block__bottom__msg__magwait3">
                      being uploaded to your G2.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </van-overlay>

          <el-button
            class="imageupload"
            size="small"
            @click="submitUpload(5)"
          >
            <span class="replacestyle">
              <span class="replacetitle">REPLACE</span>
              <span class="iconfont replaceicon">&#xe60f;</span>
            </span>
            <span class="wallpapernum"
              >WALLPAPER <span style="color: #20ff19">#5</span></span
            >
          </el-button>
        </el-upload>
      </div>
    </div>
    <div class="restore" @click="showPopup2">
      <span class="restore__icon iconfont">&#xe616;</span>
      <span class="restore__content">restore all default images</span>
    </div>
    <van-popup
      v-model:show="show2"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '621px' }"
    >
      <div class="help__title" style="color: #00ffff">IMAGE RESTORE</div>
      <div class="help__content">
        <p>
          THIS WILL RESTORE <span style="color: red">ALL</span> WALLPAPERS TO
          FECTORY
        </p>
        <p>DEFAULT STATE.YOUR IMAGES WILL BE ERASED</p>
        FROM THE DEVICE.
      </div>
      <div class="confirm">
        <span class="confirm_title">are you sure?</span>
      </div>
      <div class="confirm_content">
        <div>
          <button class="confirm_button confirm_ok" @click="restoreForm">
            yes, restore factory default
          </button>
        </div>

        <hr class="confirm_hr" />
        <div>
          <!-- <input @click="cancelForm" value="cancel" type="buttom" /> -->
          <button class="confirm_button confirm_cancel" @click="cancelForm">
            cancel
          </button>
        </div>
      </div>
    </van-popup>

    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { Notify, Toast } from "vant";
import { ref } from "vue";
import { post } from "../../utils/request";

export default {
  name: "theme",
  components: { Header },

  setup() {
    const show = ref(false);
    const show1 = ref(false);
    const show2 = ref(false);
    const waptitle = ref();
    const showPopup = () => {
      show1.value = true;
    };

    const showPopup2 = () => {
      show2.value = true;
    };

    const cancelForm = () => {
      show2.value = false;
    };

    const restoreForm = async () => {
      try {
        const result = await post("recoveryimg", {
          isrecover: 1,
          token: sessionStorage.token,
        });
        console.log(result);
        if (result?.errno === 0) {
          console.log(result);
        } else {
          console.log(result);
        }
      } catch (e) {
        console.log(e);
      }
      show2.value = false;
    };

    const actions = [
      { name: "选项一" },
      { name: "选项二" },
      { name: "选项三", subname: "描述信息" },
    ];
    return {
      show1,
      show2,
      show,
      showPopup,
      showPopup2,
      actions,
      cancelForm,
      restoreForm,waptitle
    };
  },

  data() {
    return {
      imageUrl_1: "",
      imageUrl_2: "",
      imageUrl_3: "",
      imageUrl_4: "",
      imageUrl_5: "",
      token: { token: sessionStorage.token, imagetype: "" },
      filename: "ImageName",
    };
  },
  methods: {
    handleChange(file, fileList, id) {
      console.log(id);
      switch (id) {
        case 1:
          this.imageUrl_1 = URL.createObjectURL(file.raw);
          this.filename = file.raw.name;
          break;
        case 2:
          this.imageUrl_2 = URL.createObjectURL(file.raw);
          this.filename = file.raw.name;
          break;
        case 3:
          this.imageUrl_3 = URL.createObjectURL(file.raw);
          this.filename = file.raw.name;
          break;
        case 4:
          this.imageUrl_4 = URL.createObjectURL(file.raw);
          this.filename = file.raw.name;
          break;
        case 5:
          this.imageUrl_5 = URL.createObjectURL(file.raw);
          this.filename = file.raw.name;
          break;
        default:
          console.log(file);
          console.log(fileList);
      }

      console.log(file);
      console.log(fileList);
    },

    handlebefore(file, id) {
      console.log(file);

      var imagename = "image" + (id - 1);
      console.log("before", imagename);
      if (id == 1) {
        imagename = "image";
      }
      if (imagename !== this.token.imagetype) {
        return false;
      }

      Toast.loading({
        duration: 0,
        message: "Uploading...",
        forbidClick: true,
        iconSize: "65px",
        loadingType: "spinner",
      });
    },

    submitUpload(type) {
      switch (type) {
        case 1:
          
          this.token.imagetype = "image";
          console.log("imagetype", this.token.imagetype);
          if (this.imageUrl_1 !== "") {
            this.show = true
            this.waptitle = 1;
            this.$refs.upload1.submit();
          }
          break;
        case 2:
          
          this.token.imagetype = "image1";
          if (this.imageUrl_2 !== "") {
            this.show = true
          this.waptitle = 2;
            this.$refs.upload2.submit();
          }
          break;
        case 3:
          
          this.token.imagetype = "image2";
          if (this.imageUrl_3 !== "") {
            this.show = true
          this.waptitle = 3;
            this.$refs.upload3.submit();
          }
          break;
        case 4:
          
          this.token.imagetype = "image3";
          if (this.imageUrl_4 !== "") {
            this.show = true
          this.waptitle = 4;
            this.$refs.upload4.submit();
          }
          break;
        case 5:
          
          this.token.imagetype = "image4";
          
          if (this.imageUrl_5 !== "") {
            this.show = true
          this.waptitle = 5;
            this.$refs.upload5.submit();
          }
          break;
        default:
          console.log(this.token.imagetype);
      }
    },

    handleRemove(id) {
      switch (id) {
        case 1:
          this.imageUrl_1 = "";
          this.$refs.upload1.clearFiles();
          break;
        case 2:
          this.imageUrl_2 = "";
          this.$refs.upload2.clearFiles();
          break;
        case 3:
          this.imageUrl_3 = "";
          this.$refs.upload3.clearFiles();
          break;
        case 4:
          this.imageUrl_4 = "";
          this.$refs.upload4.clearFiles();
          break;
        case 5:
          this.imageUrl_5 = "";
          this.$refs.upload5.clearFiles();
          break;
        default:
          console.log(id);
      }
    },

    handlesuccess(file, fileList) {
      Notify({ type: "primary", message: "UPLOAD SUCCESS" });
      Toast.clear();
      console.log("***", file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
<style  lang="scss" scoped>
@import "../../style/750/imageup.scss";
.wrapper1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 640px;
  height: 701px;
  background: #ffffff;
  border: 2px solid #707070;
  opacity: 1;
  border-radius: 82px;

  &__title {
    display: inline-block;
    margin-left: 200px;
    margin-top: 70px;
    width: 247px;
    height: 40px;
    font-size: 70px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }

  &__selectname {
    display: block;
    width: 640px;
    height: 166px;
    background: #808080;
    border: 2px solid #707070;
    opacity: 1;
    border-radius: 0px;
    &__content {
      display: inline-block;
      color: #ffffff;
      margin-left: 159px;
      margin-top: 42px;
      font-size: 70px;
    }
  }

  &__bottom {
    margin-top: 51px;

    &__magone {
      display: inline-block;
      margin-left: 81px;
      width: 475px;
      height: 50px;
      font-size: 70px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__magtwo {
      display: inline-block;
      margin-left: 37px;
      width: 561px;
      height: 40px;
      font-size: 44px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__hr {
      width: 475px;
      height: 0px;
      border: 2px solid #707070;
      opacity: 1;
    }
    &__msg {
      width: 557px;
      height: 189px;
      font-size: 53px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #161616;
      opacity: 1;

      &__magwait {
        display: inline-block;
        margin-left: 200px;
        margin-top: 30px;
        height: 30px;
      }
      &__magwait2 {
        display: inline-block;
        margin-left: 37px;
        width: 600px;
        margin-top: 30px;
        font-family: BebasNeue;
        height: 30px;
      }
      &__magwait3 {
        display: inline-block;
        height: 30px;
        margin-top: 30px;
        width: 100%;
        margin-left: 83px;
      }
    }
  }
}
</style>

<style lang="scss">
:root {
  --van-toast-default-width: 200px;
  --van-toast-default-min-height: 200px;
  --van-toast-icon-size: 200px;
  --van-toast-font-size: 36px;
  // --van-toast-loading-icon-color:red;
}
</style>

