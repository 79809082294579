<template>
  <div class="idfoot"
  :style="{ background: $store.state.isconnect != 'CONNECTED' ? 'red' : '#00a1a7' }"
  >
    <div class="idfoot__top">
      <span class="idfoot__id">ID:{{ id }}</span>
      <span class="idfoot__connected"
      
      >{{$store.state.isconnect}}</span>
    </div>
    <!-- <div class="idfoot__Service"></div> -->
  </div>
</template>

<script>


import { useStore } from "vuex";
import { post } from "../utils/request";
export default {
  name: "Idfoot",
  setup() {
    const store = useStore();
    let connectname = "";

    //轮询获取连接状态
    const getisconnect = async () => {
      console.log('idfoot__uid:',sessionStorage.id)
      try {
        const result = await post("fleshIsConnect", {
          uid: sessionStorage.id,
          token: sessionStorage.token,
        });

        if (result?.errno === 0) {
          result?.isconnect == 1
            ? (connectname = "CONNECTED")
            : (connectname = "disconnected");
          store.commit("changeConnectName", { connectname });
        } else {
          console.log("err", result);
        }
      } catch (e) {
        console.log(e);
      }
    };

    setInterval(()=>{ getisconnect(); }, 10000);

    const id = sessionStorage.id;
    return { id };
  },

};
</script>

<style lang="scss" scoped>
@import "../style/viriables.scss";
.idfoot {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 750px;
  height: 67px;
  border-top: 0.01rem solid $content-bgColor;
  background: #00a1a7;


  &__Service {
    height: 154px;
    width: 750px;
    background: linear-gradient(180deg, #000000 0%, #002333 100%);
  }

  &__top {
     width: 750px;
  }

  &__id {
    display: inline-block;
    padding-top: 12px;
    padding-left: 28px;
    padding-right: 220px;
    width: 301px;
    font-size: 44px;
    font-family: BebasNeue;
    font-weight: 400;
    color: #ffffff;
  }


  &__connected {
    display: inline-block;
    text-align: right;
    padding-top: 12px;
    width: 164px;
    font-size: 44px;
    font-family: BebasNeue;
    color: #ffffff;
  }
}
</style>
