<template>
  <div class="wrapper">
    <Header :currentIndex="0" />

    <div class="firmtitle">
      <span class="firmtitle__content"> VIDEO GUIDE </span>
    </div>

    <div>
        <video class="video" controls poster="http://test.yihisxminiid.com/upload/20211008/1.png">
            <source src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/SXmini.mp4" type="video/mp4">
        </video>
        <video class="video" controls poster="http://test.yihisxminiid.com/upload/20211008/1.png">
            <source src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/SXmini.mp4" type="video/mp4">
        </video>
        <video class="video" controls poster="http://test.yihisxminiid.com/upload/20211008/1.png">
            <source src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/SXmini.mp4" type="video/mp4">
        </video>
        
        
    </div>
 
  </div>

  <Idfoot />
</template>

<script>
import Idfoot from "../../components/Idfoot.vue";
import Header from "../../components/Header.vue";
export default {
  name: "video",
  components: { Idfoot, Header },
};
</script>
<style  lang="scss" scoped>
.wrapper {
  background: #000000;
  opacity: 1;
  border-radius: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0.3rem;
  right: 0;
}

.video{
    padding-left: 00.25rem;
    width: 1.5rem;
    height: 1.5rem;
}
.midcontent {
  padding-top: 0.15rem;
  text-align: center;
  height: 0.18rem;

  &__title {
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: Bebas Neue;
    font-weight: 300;
    line-height: 0rem;
    color: #ffffff;
  }

  &__title2 {
    font-size: 0.13rem;
    font-family: Bebas Neue;
    font-weight: 300;
    line-height: 0rem;
    color: #ffffff;
  }
}

.firmtitle {
  padding-top: 0.38rem;
  text-align: center;
  width: 100%;
  height: 0.18rem;
  &__content {
    font-size: 0.23rem;
    font-family: Bebas Neue;
    font-weight: 500;
    line-height: 0rem;
    color: #00ffff;
    opacity: 1;
    padding-bottom:0.01rem; 
    border-bottom:0.015rem solid #FFFFFF
  }
}
</style>

