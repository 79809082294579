<template>
  <Header :currentIndex="3" />
  <div class="wrapper">
    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content">{{ $t('header_menu.SETTINGS') }}</span>
        <hr class="firmtitle__hr" />
      </div>

      <div class="rename">
        <span class="rename__content"> memory profile rename:</span>
        <span class="iconfont rename__icon" @click="showPopup">&#xed19;</span>

        <van-popup
          v-model:show="show"
          closeable
          close-icon="close"
          position="bottom"
          :style="{ height: '30%' }"
        >
          <div class="help__title">memory profile rename:</div>
          <div class="help__content">
            <p>Rename your memory profile</p>
            <p>
              press
              <span class="iconfont" style="font-size: 28px">&#xe60f;</span> to
              upload into g2
            </p>
          </div>
        </van-popup>
      </div>

      <div class="memoryimg">
        <span class="memoryimg_title">M1:</span>
        <span class="memoryimg_iconimg iconfont">&#xe60e;</span>
        <input
          v-model="license_num1"
          @change="check_num(1)"
          class="memoryimg_content"
        />

        <span class="memoryimg_upimg iconfont" @click="postMemory(1,license_num1)"
          >&#xe60f;</span
        >
      </div>
      <div class="memoryimg">
        <span class="memoryimg_title">M2:</span>
        <span class="memoryimg_iconimg iconfont">&#xe60e;</span>
        <input
          v-model="license_num2"
          @change="check_num(2)"
          class="memoryimg_content"
        />

        <span class="memoryimg_upimg iconfont" @click="postMemory(2,license_num2)"
          >&#xe60f;</span
        >
      </div>
      <div class="memoryimg">
        <span class="memoryimg_title">M3:</span>
        <span class="memoryimg_iconimg iconfont">&#xe60e;</span>
        <input
          v-model="license_num3"
          @change="check_num(3)"
          class="memoryimg_content"
        />

        <span class="memoryimg_upimg iconfont" @click="postMemory(3,license_num3)">&#xe60f;</span>
      </div>
      <div class="memoryimg">
        <span class="memoryimg_title">M4:</span>
        <span class="memoryimg_iconimg iconfont">&#xe60e;</span>
        <input
          v-model="license_num4"
          @change="check_num(4)"
          class="memoryimg_content"
        />

        <span class="memoryimg_upimg iconfont" @click="postMemory(4,license_num4)" >&#xe60f;</span>
      </div>
      <div class="memoryimg">
        <span class="memoryimg_title">M5:</span>
        <span class="memoryimg_iconimg iconfont">&#xe60e;</span>
        <input
          v-model="license_num5"
          @change="check_num(5)"
          class="memoryimg_content"
        />

        <span class="memoryimg_upimg iconfont" @click="postMemory(5,license_num5)">&#xe60f;</span>
      </div>
    </div>
    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { ref } from "vue";
import { post } from "../../utils/request";
import { Dialog } from "vant";

export default {
  name: "setting",
  components: { Header },
  setup() {
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };

    return {
      show,
      showPopup,
    };
  },

  data() {
    return {
      license_num1: "memory1",
      license_num2: "memory2",
      license_num3: "memory3",
      license_num4: "memory4",
      license_num5: "memory5",
    };
  },

  methods: {
    postMemory: function (ver,name) {

      console.log('name:',name);

      Dialog.confirm({
        title: "confirms upgrade:",
        message: "version:" + ver,
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",
      })
        .then(() => {
          console.log(ver,name)
          this.upMemory(ver, name);
          console.log("OK1");
        })
        .catch(() => {
          console.log("***");
        });
    },

    async upMemory(ver, name) {
      try {
        const result = await post("setMemory", {
          name: name,
          ver: ver,
          token: sessionStorage.token,
          uid:sessionStorage.id,
        });
        console.log(result);
        if (result?.errno === 0) {
          console.log(result);
        } else {
          console.log(result);
        }
      } catch (e) {
        console.log(e);
      }
    },

    check_num: function (id) {
      switch (id) {
        case 1:
          this.license_num1 = this.license_num1.replace(/[^a-zA-Z0-9]/g, "");
          break;
        case 2:
          this.license_num2 = this.license_num2.replace(/[^a-zA-Z0-9]/g, "");
          break;
        case 3:
          this.license_num3 = this.license_num3.replace(/[^a-zA-Z0-9]/g, "");
          break;
        case 4:
          this.license_num4 = this.license_num4.replace(/[^a-zA-Z0-9]/g, "");
          break;
        case 5:
          this.license_num5 = this.license_num5.replace(/[^a-zA-Z0-9]/g, "");
          break;
        default:
          console.log(id);
      }
    },
  },
};
</script>
<style  lang="scss" scoped>
@import "../../style/750/setting.scss";
</style>

