<template>

 <div class="wrapper">
<Header :currentIndex="0" />
  <div class="iframe">
    <iframe  width="100%" height="100%" src="https://yhmanual.oss-cn-shenzhen.aliyuncs.com/manual/SXmini-G-Class-Chinese.html" frameborder="0" />
  </div>
 <Idfoot />
 </div>
</template>


<script>
import Idfoot from "../../components/Idfoot.vue";
import Header from "../../components/Header.vue";
export default {
  components: { Idfoot, Header }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';

.wrapper {
  background: #000000;
  opacity: 1;
  border-radius: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0rem;
  right: 0;
}

.iframe {
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  bottom: 0rem;
 
}
</style>