<template>
  <div class="header">
    <div class="header__banner">
      <img
        class="header__banner__img"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/G2.png"
      />
      <img
        class="header__banner__sxmini"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/sxmini.png"
      />
      <img
        class="header__banner__yh"
        src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/yh.png"
      />
    </div>

    <div style="background:#000000;">
    <div class="header__lang">
      
      <router-link to="panel">
        <span class="header__lang__main "><span class="iconfont header__lang__icon">&#xe611;</span> {{ $t('header_menu.dashboard') }}</span>
      </router-link>

        <span class="header__lang__changelang ">
          <van-popover
            v-model:show="showPopover"
            :actions="actions"
            @select="handleselect"
          >
            <template #reference>
              <div class="header__lang__span">
                <span class="header__lang__themeselect"
                  ><span class="iconfont headericon">&#xe613;&nbsp;</span
                  >{{ actionstitle }}</span
                >
              </div>
            </template>
          </van-popover>
          
          </span> 
    </div>
    </div>
    <div class="header__controller">
      <div
        v-for="(item, index) in headerList"
        :class="{
          header__controller__item: true,
          'header__controller__item--active': index === currentIndex,
        }"
        :key="item.icon"
      >

        <router-link :to="item.to">
          <div
            class="iconfont header__controller__item__title"
            v-html="item.icon"
          />
          <div class="header__controller__item__content">{{ $t('header_menu.'+item.text) }}</div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';

const handlethemeEffect = () => {
  const { locale,t } = useI18n();
  var datelanguage = '';
  localStorage.language?datelanguage = t('languagename.myname'):datelanguage= "language";
  const actionstitle = ref(datelanguage);

  const handleselect = (actions, index) => {
    console.log(actions, index);
    switch (index) {
      case 0:
        locale.value = 'en';
        break;
      case 1:
        locale.value = 'ch';
        break;
      default:
        break;
    }
    actionstitle.value = actions.text;
    localStorage.language = locale.value;
  };

  return {handleselect,actionstitle}
}
export default {
  name: "Header",
  props: ["currentIndex"],
  setup() {

    const headerList = [
      { icon: "&#xe605;", text: "FIRMWARE", to: { name: "firmware" } },
      { icon: "&#xe609;", text: "THEMES", to: { name: "theme" } },
      { icon: "&#xe60a;", text: "WALLPAPER", to: { name: "imageup" } },
      { icon: "&#xe627;", text: "SETTINGS", to: { name: "setting" } },
      { icon: "&#xe62b;", text: "MANUAL", to: { name: "manualrow" } },
      { icon: "&#xe606;", text: "SUPPORT", to: { name: "support" } },
    ];
    const { handleselect, actionstitle } =
      handlethemeEffect();
    localStorage.language?localStorage.language:localStorage.language= "en";
    const showPopover = ref(false);
    const actions = [
      { text: "ENGLISH" },
      { text: "简体中文" },
    ];

    return { headerList,showPopover,actions,handleselect,actionstitle };
  },
};
</script>

<style lang="scss" scoped>
@import "../style/viriables.scss";
 .headericon {
    font-size: 30px;
  }
.header {
  &__controller {
    display: flex;
    flex-wrap: nowrap;
    height: 324px;
    background: #000000;
    opacity: 1;
    text-align: center;
    font-family: BebasNeue;
    vertical-align: middle;
    font-size: 16px;

    &__item {
      margin-right: 2px;
      width: 123px;
      height: 96px;
      color: #1fa4fc;
      background: linear-gradient(180deg, #5e5e5e 0%, #131313 100%);
      vertical-align: middle;

      &__title {
        padding-left: 40px;
        color: #ffffff;
        font-size:38px;
        padding-top: 18px;
        width:42px;
        height:42px;
        vertical-align: middle;
        padding-bottom: 7px;
      }

      &__content {
        display: inline-block;
        font-size: 16px;
        bottom: 0;
        height: 19px;
        color: #ffffff;
      }

      a {
        color: $content-fontcolor;
        text-decoration: none;
      }
      &--active {
        .header__controller__item__title {
          color: #00fff7;
        }
        .header__controller__item__content {
          color: #00fff7;
        }
      }
    }


  }

  &__banner {
    overflow: hidden;
    height: 167px;
    // background-image: url(../assets/Gclassbanner.png);
    background: url(../assets/Gclassbanner.png) no-repeat center bottom,linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #131313 100%);

    &__img {
      display: inline-block;
      width: 107px;
      margin-top: 10px;
      padding-bottom: 14px;
      margin-left: 14px;
    }

    &__sxmini {
      display: inline-block;
      text-align: left;
      width: 186px;

      position: absolute;
      top: 0;
      margin-top: 15px;
      margin-left: 443px;
      margin-bottom: 22px;
      height: 47px;
    }

    &__yh {
      display: inline-block;
      position: absolute;
      top: 0;
      margin-top: 84px;
      text-align: left;
      width: 68px;
      padding-bottom: 40px;
      margin-left: 547px;
      height: 47px;
    }
  }

  &__lang {
    margin-left: 6px;
    background: #000000;
    height: 57px;
    text-align: left;
    font-family: BebasNeue;

    &__main {
      display: inline-block;
      color: #5e5e5e;
      padding-top: 10px;
      height: 39px;
      width: 157px;
      font-size: 26px;
      background: #ffffff;
      border-radius: 14px;
    }

    &__changelang {
      display: inline-block;
      margin-left: 390px;
      color: #5e5e5e;
      padding-top: 10px;
      
      height: 39px;
      width: 190px;
      font-size: 26px;
      background: #ffffff;
      font-family: BebasNeue;
      border-radius: 14px;
    }


    &__themeselect{
      color: #5E5E5E;
    }
    &__span {
      display: inline-block;
      text-align: center;
      margin-left: 18px;
      line-height:1;
    }

    &__icon {
      width: 20px;
      height: 18px;
      margin-left: 6px;
      margin-right: 6px;
      font-size:26px ;
    }
  }


}
</style>
<style  lang="scss" >
:root {
  --van-popover-action-width: 196px;
  --van-popover-action-height: 60px;
  --van-popover-action-font-size: 30px;
  // --van-toast-loading-icon-color:red;
}
</style>
