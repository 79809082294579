<template>
  <Header :currentIndex="5" />
  <div class="wrapper">
    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content">{{ $t("header_menu.SUPPORT") }} </span>
      </div>

      <div class="faq">
        <div class="faq__title">FAQ</div>
        <van-collapse v-model="activeNames" accordion>
          <div
            class="faq__item"
            v-for="(item) in faqList"
            :key="item.id"
            @click="active(item.id)"
          >
            <van-collapse-item :name="item.id">
              <template #title>
                <div class="faq__item">
                  <span
                    :class="{
                      iconfont: true,
                      faq__item__iconfont: true,
                      faq__item__iconselect: activeindex === item.id,
                    }"
                    >&#xe612;</span
                  >
                  <span class="faq__item__question" v-html="item.faqtitle" ></span>
                </div>
              </template>
              {{ item.text }}
            </van-collapse-item>
          </div>
        </van-collapse>
      </div>
<div class="faq">
      <van-pagination
        v-model="currentPage"
        :total-items="15"
        :show-page-size="5"
        :items-per-page="5"
        @change="getpagelist(currentPage, 5)"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
    </div>

    <div class="onebox">
      <div class="connact">CONTACT:</div>
      <div class="connactweb">WWW.YIHISXMINI.COM/CONTACT.HTML</div>
    </div>

    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content"> MAINTENANCE </span>
      </div>

      <div class="firmware__title">REINSTALL LATEST SYSTEM FIRMWARE :</div>

      <div class="newver">
        <span>
          <img
            class="newver__installimg"
            @click="supportinstall"
            src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/install.png"
          />
        </span>
        <div class="newver__num">
          <span class="newver__num__content"> S.firmware:</span>
          <span class="newver__num__num"> V1.81 </span>
        </div>
        <div class="newver__date">
          <span class="newver__date__content"> DATE: </span>
          <span class="newver__date__time"> 08/10/2021</span>
        </div>

        <div class="newver__content">
          <span class="newver__content__what"> WHAT'S NEW: </span>
          <span class="newver__content__content"
            >- enhanced temp control accuracy</span
          >
          <span class="newver__content__content"
            >- enhanced smart boost feature</span
          >
        </div>
      </div>
    </div>
    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>

  <Idfoot />
</template>

<script>
import Idfoot from "../../components/Idfoot.vue";
import Header from "../../components/Header.vue";
import { ref } from "vue";
import { Dialog } from "vant";

const handlethemeEffect = () => {
  const handleselect = (actions, index) => {
    console.log(actions, index);
  };
  return { handleselect };
};

const supportinstallEffect = () => {
  const supportinstall = () => {
    Dialog.confirm({
      title: "confirms upgrade:",
      message: "Upgrade all",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",
    })
      .then(() => {
        console.log("OK");
      })
      .catch(() => {
        console.log("***");
      });
  };
  return { supportinstall };
};

export default {
  name: "theme",
  components: { Idfoot, Header },

  data() {
    const ALLfaqList = [
      { faqtitle: "1 FREQUENTLY ASKED QUESTION", text: "首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111",id:1 },
      { faqtitle: "2 FREQUENTLY ASKED QUESTION", text: "我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的",id:2},
      { faqtitle: "3 FREQUENTLY ASKED QUESTION", text: "111111111111111111111111111111111我的我的我的我的我的我的我的我的我的我的我的我的我的" ,id:3},
      { faqtitle: "4 FREQUENTLY ASKED QUESTION", text: "我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的",id:4 },
      { faqtitle: "5 FREQUENTLY ASKED QUESTION", text: "首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111" ,id:5},
      { faqtitle: "6 FREQUENTLY ASKED QUESTION", text: "111" ,id:6},
      { faqtitle: "7 FREQUENTLY ASKED QUESTION", text: "首页111" ,id:7},
      { faqtitle: "8 FREQUENTLY ASKED QUESTION", text: "我的" ,id:8},
      { faqtitle: "9 FREQUENTLY ASKED QUESTION", text: "111" ,id:9},
      { faqtitle: "10 FREQUENTLY ASKED QUESTION", text: "首页111" ,id:10},
      { faqtitle: "11 FREQUENTLY ASKED QUESTION", text: "我的" ,id:11},
      { faqtitle: "12 FREQUENTLY ASKED QUESTION", text: "111" ,id:12},
      { faqtitle: "13 FREQUENTLY ASKED QUESTION", text: "首页111" ,id:13},
      { faqtitle: "13 FREQUENTLY ASKED QUESTION", text: "我的" ,id:14},
      { faqtitle: "15 FREQUENTLY ASKED QUESTION", text: "111" ,id:15},
    ];

    const faqList = [
            { faqtitle: "1 FREQUENTLY ASKED QUESTION", text: "首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111",id:1 },
      { faqtitle: "2 FREQUENTLY ASKED QUESTION", text: "我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的",id:2},
      { faqtitle: "3 FREQUENTLY ASKED QUESTION", text: "111111111111111111111111111111我的我的我的我的我的我的我的我的我的我的我的我的我的" ,id:3},
      { faqtitle: "4 FREQUENTLY ASKED QUESTION", text: "我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的我的",id:4 },
      { faqtitle: "5 FREQUENTLY ASKED QUESTION", text: "首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111首页113首页111" ,id:5},
    ];

    return {
      activeindex: "",
      faqList,
      ALLfaqList,
    };
  },
  methods: {
    active(index) {
      this.activeindex = index;
    },

    getpagelist(page, perpage) {
      console.log(page, perpage);
      // for (var i = 0; i < myArray.length; i++) {
      //   console.log(myArray[i]);
      // }
      var str1 = this.getpage(page, perpage);
      this.faqList = str1
      console.log('str1',str1);
    },

    //将json分页求第一页的数据
    getpage(pageIndex, pageSize) {
      var arg = this.getArg(this.ALLfaqList);
      console.log(arg)
      var o = arg["Array"][0] || [];
      
      if (o.constructor == Array) {
        console.log('o',arg['object'])
        var re = [];
        var st = (pageIndex - 1) * pageSize;
        var ed = st + pageSize;
        console.log('ed',ed)
        for (var i = st; i < ed; i++) {
           console.log('i',arg['object'][i])
          if (arg['object'][i] != undefined) {
            re.push(arg['object'][i]);
          }
        }
        return re;
      }
    },
    //获取参数
    getArg(arg) {
      var ret = {
        number: [],
        string: [],
        boolean: [],
        object: [],
        Object: [],
        Array: [],
        function: [],
        Date: [],
        null: [],
      };
      for (var i = 0, len = arg.length; i < len; i++) {
        var ar = arg[i];
        if (typeof ar == "number") {
          ret["number"].push(ar);
        } else if (typeof ar == "string") {
          ret["string"].push(ar);
        } else if (typeof ar == "boolean") {
          ret["boolean"].push(ar);
        } else if (typeof ar == "function") {
          ret["function"].push(ar);
        } else if (typeof ar == "object") {
          if (ar) {
            if (ar.constructor == Object) {
              ret["object"].push(ar);
              ret["Object"].push(ar);
            } else if (ar.constructor == Array) {
              ret["object"].push(ar);
              ret["Array"].push(ar);
            } else if (ar.constructor == Date) {
              ret["Date"].push(ar);
            }
          } else {
            ret["null"].push(ar);
          }
        }
      }
      return ret;
    },

  },

  setup() {
    const activeNames = ref("");
    const currentPage = ref(1);
    console.log(activeNames);
    const { supportinstall } = supportinstallEffect();
    const { handleselect } = handlethemeEffect();
    const showPopover = ref(false);
    const actions = [
      { text: "THEME1" },
      { text: "THEME2" },
      { text: "THEME3" },
    ];

    return {
      actions,
      showPopover,
      handleselect,
      supportinstall,
      activeNames,
      currentPage,
    };
  },
};
</script>

<style  lang="scss" >
:root {
  --van-collapse-item-content-background-color: transparent;
  --van-cell-background-color: transparent;
  --van-cell-active-color: transparent;
  --van-collapse-item-content-font-size:26px;
}
</style>

<style  lang="scss" scoped>
::v-deep .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after,
.van-collapse-item--border::after {
  border-width: 0;
  border-top: 0;
}

::v-deep .van-cell::after {
  border-bottom: 0;
}

::v-deep .van-cell__right-icon {
  color: transparent;
}

::v-deep .van-cell {
  padding-top: 10px;
}

::v-deep .van-collapse-item__content {
  color: #ffffff;
}

::v-deep .van-pagination{
  font-size:36px;
}
::v-deep .van-pagination__item{
  min-width:60px;
}
.firmware__title {
  margin-left: 33px;
  margin-top: 15px;
  font-family: BebasNeue;
  font-size: 35px;
  font-weight: 400;
  color: #ffffff;
}

.connact {
  font-size: 44px;
  margin-top: 12px;
  color: #ffffff;
  font-family: BebasNeue;
  margin-left: 42px;
}
.connactweb {
  font-size: 44px;
  margin-top: 4px;
  margin-bottom: 30px;
  margin-left: 42px;
  color: #00ffff;
}
.faq {
  display: inline-block;
  margin-left: 42px;
  margin-bottom: 50px;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;

  &__title {
    color: #ffffff;
    font-size: 44px;
    margin-top: 39px;
    margin-bottom: 12px;
  }

  &__item {
    margin-top: 4px;
    &__iconfont {
      display: inline-block;
      font-size: 35px;
      margin-right: 19px;
      color: #ffffff;
    }
    &__iconselect {
      display: inline-block;

      transform: rotate(90deg);
    }

    &__question {
      font-size: 35px;
      color: #ffffff;
    }
  }
}

.newver {
  margin-top: 37px;
  margin-bottom: 50px;
  color: #ffffff;
  font-size: 44px;
  font-family: BebasNeue;
  margin-left: 33px;
  display: inline-block;

  &__installimg {
    width: 105px;
    position: absolute;
    right: 58px;
  }
  &__num {
    &__content {
      display: inline-block;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }

    &__num {
      padding-left: 88px;
      font-weight: 400;
      line-height: 0px;
      color: #00ffff;
      opacity: 1;
    }
  }

  &__date {
    &__content {
      display: inline-block;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }

    &__time {
      margin-left: 188px;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }

  &__content {
    margin-top: 10px;

    display: block;

    font-size: 35px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #ffffff;

    &__what {
      display: block;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
      margin-bottom: 45px;
      margin-top: 16px;
    }

    &__content {
      display: block;
      margin-bottom: 45px;
      margin-left: 33px;

      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}
</style>


