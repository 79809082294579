<template>
<Header :currentIndex="4" />
  <div class="wrapper">
  
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('manualrow.videoname')" name="first">
        <div class="onebox">

          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/mini.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/SXmini.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">Basic operation</div>
          </div>


          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/update.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/03000a010059c9e404a4f80000000145a20c42-5879-3847-0f72-a2c7c8886624.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">Basic operation</div>
          </div>

          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/g.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/03000a010059f943adb5a2367cd4443ae8926c-98bc-8249-644d-653f055b5028.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">how to temp control</div>
          </div>

          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/mini.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/SXmini.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">Basic operation</div>
          </div>

          
          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/update.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/03000a010059c9e404a4f80000000145a20c42-5879-3847-0f72-a2c7c8886624.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">Basic operation</div>
          </div>

          <div class="videoitem">
          <video
            class="video"
            controls
            poster="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/g.png"
          >
            <source
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/video/03000a010059f943adb5a2367cd4443ae8926c-98bc-8249-644d-653f055b5028.mp4"
              type="video/mp4"
            />
          </video>
          <div class="videotitle">how to temp control</div>
          </div>

        </div>

      </el-tab-pane>

      <el-tab-pane  :label="$t('manualrow.manualname')" name="second">
        <div class="onebox">
        <div class="pdfcontent">
          <el-row>
            <el-col
              :span="8"
              v-for="(item, index) in manual_1"
              :key="index"
              
            >
              <router-link to="instructions">
                <el-card
                  :body-style="{ padding: '0px'}"
                >
                  <img :src="item.icon" class="image" />
                  <div style="padding: 14px">
                    <span class="manualtitle">{{ item.text }}</span>
                  </div>
                </el-card>
              </router-link>
            </el-col>

            <el-col
              :span="8"
              v-for="(item, index) in manual_2"
              :key="index"
              
            >
              <router-link to="instructions">
                <el-card
                  :body-style="{ padding: '0px'}"
                >
                  <img :src="item.icon" class="image" />
                  <div style="padding: 14px">
                    <span class="manualtitle">{{ item.text }}</span>
                  </div>
                </el-card>
              </router-link>
            </el-col>

            <el-col
              :span="8"
              v-for="(item, index) in manual_3"
              :key="index"
              
            >
              <router-link to="instructions">
                <el-card
                :body-style="{ padding: '0px' }">
                  <img :src="item.icon" class="image" />
                  <div style="padding: 14px">
                    <span class="manualtitle">{{ item.text }}</span>
                  </div>
                </el-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
        </div>
      </el-tab-pane>
    </el-tabs>
       <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service |  Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right">© YIHI ELECTRONIC CO.,LTD 2021</span>
      </div>
    </div>
  </div>

  <Idfoot />
</template>

<script>
import Idfoot from "../../components/Idfoot.vue";
import Header from "../../components/Header.vue";


export default {
  name: "manualrow",
  components: { Idfoot, Header },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },

  setup() {
    const activeName = "first";


    const manual_1 = [
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "ENGLISH",
      },
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },
    ];
    const manual_2 = [
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "CHINA",
      },
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },{
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },
    ];
    const manual_3 = [
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "ENGLISH",
      },
      {
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },{
        icon: "http://sxmini750.oss-cn-shenzhen.aliyuncs.com/img/PDFicon.png",
        text: "FREACH",
      },
    ];
    return { manual_1, manual_2, manual_3, activeName };
  },
};
</script>
<style  lang="scss" scoped>

::v-deep .el-card {
  border: 0;
  background: transparent;
}


::v-deep .el-tabs__item.is-active {
  color: #00ffff;
}
::v-deep .el-tabs__header {
  margin-top: 68px;
  margin-left: 30px;

}
::v-deep .el-tabs__item {
  font-size: 72px;
  margin-top: 68px;
  margin-left: 70px;
  color: #ffffff;
}
::v-deep .el-tabs__nav-wrap::after {
  width: 0px;
}


::v-deep .el-tabs__active-bar {
  background-color: #00ffff;
}
.pdfcontent {
  padding-top: 0.15rem;
  padding-left: 0.39rem;
}

.video {

  margin-top: 35px;
  width: 315px;
  height: 175px;
}
.videoitem {
  display: inline-block;
   margin-left: 20px;
  width: 315px;
}
.videotitle {
  text-align: center;
  margin-top: 16px;

  font-size: 35px;
  color: #ffffff;
}


.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 136px;
  height: 175px;
  margin-left: 30px;
  display: block;
 
}

.manualtitle {

  margin-left: 30px;
  font-size: 35px;
  font-family: BebasNeue;
  font-weight: 400;
  line-height: 0;
  color: #ffffff;
  opacity: 1;
}

</style>

