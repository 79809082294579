<template class="dashboard">
<Headerpanel />

  <div class="bgmid">
    <div>
    <div class="bgmid__inline">
      <transition name="fade">
        <span class="bgmid__inline__title"> {{ $t('header_menu.dashboard') }} </span>
      </transition>
    </div>
    <div class="bgmid__lang">
      <span class="bgmid__lang__lang ">
          <van-popover
            v-model:show="showPopover"
            :actions="actions"
            @select="handleselect"
          >
            <template #reference>
              <div class="bgmid__lang__span">
                <span class="bgmid__lang__themeselect"
                  ><span class="iconfont headericon">&#xe613;&nbsp;</span
                  >{{ actionstitle }}</span
                >
              </div>
            </template>
          </van-popover>
          </span> 
    </div>
</div>
    <div class="bgmid__grid">
      <div class="bgmid__grid__row">
        <div class="bgmid__grid__setting bgmid__grid__setting1">
          <router-link to="firmware">
            <img
              class="bgmid__grid__icoimage"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/update.png"
            />
            <div class="bgmid__grid__content">{{ $t('header_menu.FIRMWARE') }}</div>
          </router-link>
        </div>

        <div
          class="bgmid__grid__setting bgmid__grid__setting2"
          style="margin-left: 9px"
        >
          <router-link to="theme">
            <img
              class="bgmid__grid__icoimagetheme"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/theme.png"
            />
            <div class="bgmid__grid__icocontent">{{ $t('header_menu.THEMES') }}</div>
          </router-link>
        </div>

        <div
          class="bgmid__grid__setting bgmid__grid__setting3"
          style="margin-left: 9px"
        >
          <router-link to="imageup">
            <img
              class="bgmid__grid__icoimagewallpaper"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/wallpaper.png"
            />
            <div class="bgmid__grid__icocontent">{{ $t('header_menu.WALLPAPER') }}</div>
          </router-link>
        </div>
      </div>

      <div class="bgmid__grid__row">
        <div class="bgmid__grid__setting bgmid__grid__setting4">
          <router-link to="setting">
            <img
              class="bgmid__grid__icoimagesetting"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/setting.png"
            />
            <div class="bgmid__grid__icocontent">{{ $t('header_menu.SETTINGS') }}</div>
          </router-link>
        </div>

        <div
          class="bgmid__grid__setting bgmid__grid__setting5"
          style="margin-left: 9px"
        >
          <router-link to="manualrow">
            <img
              class="bgmid__grid__icoimagemanual"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/manual.png"
            />
            <div class="bgmid__grid__icocontent">{{ $t('header_menu.MANUAL') }}</div>
          </router-link>
        </div>

        <div
          class="bgmid__grid__setting bgmid__grid__setting6"
          style="margin-left: 9px"
        >
          <router-link to="support">
            <img
              class="bgmid__grid__icoimagesupport"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/ico/support.png"
            />
            <div class="bgmid__grid__icocontent">{{ $t('header_menu.SUPPORT') }}</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="bgmid__inline">
      <span class="bgmid__inline__title"> LATEST PRODUCTS </span>
    </div>
    <div class="bgmid__banner">
      <img class="banner__img2" src="../../assets/mk.jpg" />
    </div>
    <div class="bgmid__banner">
      <img class="banner__img3" src="../../assets/na.jpg" />
    </div>

    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>

  <Idfoot />
</template>

<script>
import Idfoot from "../../components/Idfoot.vue";
import Headerpanel from "../../components/Headerpanel.vue";
import { post } from "../../utils/request";
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';



const handlethemeEffect = () => {
  const { locale,t } = useI18n();
  var datelanguage = '';
  localStorage.language?datelanguage = t('languagename.myname'):datelanguage= "language";
  const actionstitle = ref(datelanguage);

  const handleselect = (actions, index) => {
    console.log(actions, index);
    switch (index) {
      case 0:
        locale.value = 'en';
        break;
      case 1:
        locale.value = 'ch';
        break;
      default:
        break;
    }
    actionstitle.value = actions.text;
    localStorage.language = locale.value;
  };

  return {handleselect,actionstitle}
}


const themeEffext = () => {
  const store = useStore();
  const getversionlist = async () => {
    try {
     
      const result1 = await post("getupversionlist", {
        t1: sessionStorage.theme1,
        t2: sessionStorage.theme2,
        t3: sessionStorage.theme3,
        setting: sessionStorage.setting,
        token: sessionStorage.token,
      });

      if (result1?.errno === 0) {
        console.log("getupversionlist",result1);
        
        store.commit('changeDatalist', { result1 });
        console.log(store.state.dataList.t1)
        
        //sessionStorage.setItem('data', JSON.stringify(result1));
        
      } else {
        console.log("err", result1);
      }
    } catch (e) {
      console.log(e);
    }
  };




  const getintheme = async () => {
  try {
      const result = await post("getthemelist", {
        theme: sessionStorage.theme1,
        token: sessionStorage.token,
      });
      console.log("themeinit", store.state.initData.theme1);
      if (result?.errno === 0) {

        console.log('themelist', result?.themelist)
        store.commit('changeThemelist', { result });
        console.log('changeThemelist',store.state.themelist.themelist)

        //sessionStorage.setItem('themelist', JSON.stringify(result?.themelist));
 
      } else {
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  }
 
  return {getversionlist,getintheme};
};

export default {
  name: "panel",
  components: { Idfoot,Headerpanel },

  setup() {
    
    const showPopover = ref(false);
    const actions = [
      { text: "ENGLISH" },
      { text: "简体中文" },
    ];
    
    const { getversionlist,getintheme} = themeEffext();
    const { handleselect, actionstitle } =
      handlethemeEffect();
    localStorage.language?localStorage.language:localStorage.language= "en";
    // setInterval(()=>{ getfleshstorge(),getversionlist() }, 30000);

    getversionlist();
    // getfleshstorge()

    getintheme();
    return {
      getversionlist,showPopover,actions,handleselect, actionstitle
    };
  },

};
</script>

<style lang="scss" scoped>
// @import "../../style/viriables.scss";

:root {
  --van-grid-item-content-background-color: #2b255d;
}
.headericon {
    
    font-size: 30px;
  }
.bgmid {
  background: #000000;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 268px;
  bottom: 0;
  right: 0;
  width: 750px;

  &__lang{
    display: inline-block;
    position:absolute;
      right: 30px;
      top:70px;
    &__lang {
      display: inline-block;
      color: #5e5e5e;
      padding-top: 10px;
      height: 39px;
      width: 190px;
      font-size: 26px;
      background: #ffffff;
      font-family: BebasNeue;
      border-radius: 14px;
    }


    &__themeselect{
      color: #5E5E5E;
    }
    &__span {
      display: inline-block;
      text-align: center;
      margin-left: 18px;
      line-height:1;
    }

  }



  &__inline {
    display: inline-block;
    margin-top: 96px;
    margin-bottom: 12px;
    width: 750px;
    opacity: 1;
    text-align: center;
    // display: inline-block;
    &__title {
      display: inline-block;
      
      font-size: 72px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }

  &__grid {
    margin-left: 23px;
    opacity: 1;
    border-radius: 0px;
    vertical-align: middle;
    width: 704px;

    &__icoimage {
      padding-left: 74px;
      margin-top: 60px;
      width: 78px;
      height: 104px;
    }

    &__icoimagetheme {
      padding-left: 82px;
      margin-top: 74px;
      width: 59px;
      height: 78px;
    }

    &__icoimagewallpaper {
      padding-left: 74px;
      margin-top: 74px;
      width: 78px;
      height: 78px;
    }

    &__icoimagesetting {
      padding-left: 75px;
      margin-top: 74px;
      width: 70px;
      height: 78px;
    }

    &__icoimagemanual {
      padding-left: 77px;
      margin-top: 74px;
      width: 73px;
      height: 78px;
    }

    &__icoimagesupport {
      padding-left: 79px;
      margin-top: 74px;
      width: 67px;
      height: 78px;
    }

    &__content {
      text-align: center;
      margin-top: 21px;
      font-size: 26px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
    }

    &__icocontent {
      text-align: center;
      margin-top: 32px;
      font-size: 26px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
    }

    &__img {
      color: #ffffff;
      padding-left: 17px;
      padding-top: 5px;
      font-size: 8px;

      vertical-align: middle;
    }

    &__row {
      width: 704px;
      display: flex;
      margin-bottom: 16px;
    }

    &__setting {
      width: 704px;
      flex: 1 1 33.33%;
    }

    &__setting:active {
      background-color: rgba(46, 103, 222, 0.13);
    }

    &__setting1 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(10, 254, 245, 0.1) 0%,
        rgba(3, 231, 239, 0.4) 100%
      );
    }
    &__setting2 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(226, 0, 251, 0.1) 0%,
        rgba(220, 5, 213, 0.4) 100%
      );
    }
    &__setting3 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(0, 119, 255, 0.1) 0%,
        rgba(0, 136, 255, 0.4) 100%
      );
    }
    &__setting4 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(255, 12, 12, 0.1) 0%,
        rgba(255, 0, 0, 0.4) 100%
      );
    }
    &__setting5 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(234, 251, 0, 0.1) 0%,
        rgba(255, 196, 0, 0.4) 100%
      );
    }
    &__setting6 {
      width: 224px;
      height: 224px;
      background: linear-gradient(
        180deg,
        rgba(0, 190, 25, 0.1) 0%,
        rgba(68, 213, 68, 0.4) 100%
      );
    }
  }
}
</style>
<style  lang="scss" >
:root {
  --van-popover-action-width: 196px;
  --van-popover-action-height: 60px;
  --van-popover-action-font-size: 30px;
  // --van-toast-loading-icon-color:red;
}
</style>