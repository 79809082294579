<template>
  <Header :currentIndex="1" />
  <div class="wrapper">
    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content"> {{ $t("header_menu.THEMES") }} </span>
        <hr class="firmtitle__hr" />
      </div>
      <div class="midcontent">
        <div class="midcontent__title">CURRENTLY INSTALLED ON YOUR DEVICE:</div>
      </div>

      <div class="thememg">
        <div class="thememg__vermgtitle">
          <div class="thememg__vermgtitle__orderone">
            <p class="thememg__vermgtitle__p">
              <span class="thememg__vermgtitle__title">THEME 1:</span>
              <span class="thememg__vermgtitle__cont"> RACE1</span>
            </p>
          </div>
          <span class="thememg__vermgtitle__img">
            <img
              class="banner__img"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/THEME2x.png"
            />
          </span>
          <div class="thememg__vermgtitle__order">
            <p
              class="thememg__vermgtitle__num"
              :style="{
                color: $store.state.dataList.t1.isnew != 'NEW!' ? '#00FFFF' : '#FFFF19',
              }"
            >
              V{{ $store.state.dataList.t1.version }}
            </p>
            <div class="thememg__vermgtitle__new">
              <span class="thememg__vermgtitle__new__content">{{
                $store.state.dataList.t1.isnew
              }}</span>
            </div>
          </div>
        </div>

        <div class="thememg__vermgtitle">
          <div class="thememg__vermgtitle__orderone">
            <p class="thememg__vermgtitle__p">
              <span class="thememg__vermgtitle__title">THEME 2:</span>
              <span class="thememg__vermgtitle__cont">MINIAL1</span>
            </p>
          </div>
          <span class="thememg__vermgtitle__img">
            <img
              class="banner__img"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/THEME22x.png"
            />
          </span>
          <div class="thememg__vermgtitle__order">
            <p
              class="thememg__vermgtitle__num"
              :style="{
                color: $store.state.dataList.t2.isnew != 'NEW!' ? '#00FFFF' : '#FFFF19',
              }"
            >
              V{{ $store.state.dataList.t2.version }}
            </p>
            <div class="thememg__vermgtitle__new">
              <span class="thememg__vermgtitle__new__content">{{
                $store.state.dataList.t2.isnew
              }}</span>
            </div>
          </div>
        </div>

        <div class="thememg__vermgtitle">
          <div class="thememg__vermgtitle__orderone">
            <p class="thememg__vermgtitle__p">
              <span class="thememg__vermgtitle__title">THEME 3:</span>
              <span class="thememg__vermgtitle__cont">ZOOM1</span>
            </p>
          </div>
          <span class="thememg__vermgtitle__img">
            <img
              class="banner__img"
              src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/THEME32x.png"
            />
          </span>
          <div class="thememg__vermgtitle__order">
            <p
              class="thememg__vermgtitle__num"
              :style="{
                color: $store.state.dataList.t3.isnew != 'NEW!' ? '#00FFFF' : '#FFFF19',
              }"
            >
              V{{ $store.state.dataList.t3.version }}
            </p>
            <div class="thememg__vermgtitle__new">
              <span class="thememg__vermgtitle__new__content">{{
                $store.state.dataList.t3.isnew
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="onebox">
      <div class="firmtitle">
        <span class="firmtitle__content"> DOWNLOAD NEW THEMES </span>
      </div>
      <div class="midcontent">
        <div class="midcontent__selecttitle">
          DOWNLOAD INTO
          <van-popover
            v-model:show="showPopover"
            theme="dark"
            :actions="actions"
            @select="handleselect"
          >
            <template #reference>
              <div class="midcontent__span">
                <span class="midcontent__themeselect"
                  ><span class="iconfont themeicon">&#xe613;&nbsp;</span
                  >{{ actionstitle }}</span
                >
              </div>
            </template>
          </van-popover>
          <div class="icon">
            <p class="iconfont supporticon" @click="showPopup">&#xed19;</p>

            <van-popup
              v-model:show="show"
              closeable
              close-icon="close"
              position="bottom"
              :style="{ height: '40%' }"
            >
              <div class="help__title">HOW TO DOWNLOAD THEMES</div>
              <div class="help__content">
                <p>G2 has 3 installable theme slots</p>
                <p>1. select which theme slot# to download into.</p>
                <p>2. click on your chosen theme Install button.</p>
                <p>3. The selected theme will be downloaded and</p>
                <p>installed on your device.</p>

                <p style="margin-top: 68px">
                  * theme slot #1 differs in size and selections
                </p>
              </div>
            </van-popup>
          </div>
        </div>
      </div>

      <div
        class="themeinstall"
        v-for="(item, index) in $store.state.themelist.themelist"
        :key="index"
      >
        <span>
          <img class="themeinstall__img" :src="item.image2x" />
        </span>
        <div class="themeinstall__order">
          <p class="themeinstall__title">{{ item.themename }}</p>
          <p class="themeinstall__version">{{ item.version }}</p>
        </div>

        <span @click="$store.state.isconnect == 'CONNECTED'
                ? (showconfirmtheme = true,selectUpTheme = item.upurl,selectUpThemeType = item.type,selectUpThemeVersion = item.version )
                : (showConnectTheme = true)">
          
          <img
            class="themeinstall__installimg"
            src="https://sxlogin.oss-cn-shenzhen.aliyuncs.com/G/install.png"
          />
        </span>

        <van-overlay :show="showconfirmtheme" @click="showconfirmtheme = false">
        <div class="wrapper1" @click.stop>
          <div class="block">
            <span class="block__title"> installing: </span>
            <hr class="block__hr" />
            <span class="block__msg"> {{ actionstitle }} </span>
            <span class="block__msg1">{{ selectUpThemeVersion }} (English version) </span>
            <div class="block__confirm">
              <div class="block__confirm__cancel">
                <span
                  class="block__confirm__cancel__title"
                  @click="showconfirmtheme = false"
                  >cancel</span
                >
              </div>
              <span class="block__confirm__y">
                <!-- oninstall(item.version), upurl-->
                <span
                  class="block__confirm__y__title"
                  @click="upClick(selectUpTheme,selectUpThemeType),(showconfirmtheme = false),(showthememsg = true)"
                  >confirm</span
                >
              </span>
            </div>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="showConnectTheme" @click="showConnectTheme = false">
        <div class="wrapper1" @click.stop>
          <div class="block">
            <span class="block__title"> DISCONNECT </span>
            <hr class="block__hr" />
            <span class="block__msg"> DISCONNECT </span>
            <!-- <span class="block__msg1"> (--!) </span> -->
            <div class="block__confirm">
              <div class="block__confirm__cancel">
                <span
                  class="block__confirm__cancel__title"
                  @click="showConnectTheme = false"
                  >cancel</span
                >
              </div>

              <span class="block__confirm__y">
                <span class="block__confirm__y__title">confirm</span>
              </span>
            </div>
          </div>
        </div>
      </van-overlay>


      <van-overlay :show="showthememsg" @click="showthememsg = false">
            <div class="wrapper2" @click="showthememsg = false">
              <div class="block1">
                <span class="block1__title"> uploading: </span>
                <div class="block1__selectname">
                  <span class="block1__selectname__content"> {{ actionstitle }} </span>
                </div>
                <div class="block1__bottom">
                  <span class="block1__bottom__magone">
                    do not shut down G2
                  </span>
                  <span class="block1__bottom__magtwo">
                    do not exit wifi mode until completion
                  </span>
                  <hr class="block1__bottom__hr" />
                  <div class="block1__bottom__msg">
                    <span class="block1__bottom__msg__magwait">
                      please wait,
                    </span>
                    <span class="block1__bottom__msg__magwait2">
                      while the Installation files are
                    </span>
                    <span class="block1__bottom__msg__magwait3">
                      being uploaded to your G2.
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </van-overlay>

      </div>

      
    </div>
    <div class="bottomcontent">
      <span style="color: #00fff7"> VIEW MORE </span>
      THEMES FOR THEME SLOTS 2 AND 3
    </div>

    <div class="servicefoot">
      <div class="servicefoot__title">Terms of Service | Privacy-Policy</div>
      <hr class="servicefoot__hr" />
      <div class="servicefoot__meta">
        <span class="servicefoot__meta__left">DASHBOARD V1.26</span>
        <span class="servicefoot__meta__right"
          >© YIHI ELECTRONIC CO.,LTD 2021</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { ref, reactive } from "vue";
import { post } from "../../utils/request";
import { useStore } from 'vuex';

const handlethemeEffect = () => {
  const store = useStore();
  const actionstitle = ref("THEME1");

  const themelist = reactive({ list: [] });
  console.log("触发1", themelist);

  const handleselect = (actions, index) => {
    console.log(actions, index);
    actionstitle.value = actions.text;

    getthemelist(actionstitle);

    console.log("点击", themelist);
  };
  const tlist = ref(sessionStorage.theme1);
  // const inthemelistta = reactive([]);
  const getthemelist = async (actionstitle) => {
    
    console.log("te", actionstitle.value);

    switch (actionstitle.value) {
      case "THEME1":
        tlist.value = sessionStorage.theme1;
        break;
      case "THEME2":
        tlist.value = sessionStorage.theme2;
        break;
      case "THEME3":
        tlist.value = sessionStorage.theme3;
        break;
      default:
        break;
    }

    try {
      const result = await post("getthemelist", {
        theme: tlist.value,
        token: sessionStorage.token,
      });
      console.log();
      if (result?.errno === 0) {
        console.log("themelist", result?.themelist);

        store.commit('changeThemelist', { result });

        // sessionStorage.setItem("themelist", JSON.stringify(result?.themelist));
        // themelist.list = JSON.parse(sessionStorage.getItem("themelist"));
      } else {
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { handleselect, actionstitle, getthemelist, tlist };
};

const installEffect = () => {
   const store = useStore();
  const getversionlist = async () => {
    try {
      const result1 = await post("getupversionlist", {
        t1: sessionStorage.theme1,
        t2: sessionStorage.theme2,
        t3: sessionStorage.theme3,
        setting: sessionStorage.setting,
        token: sessionStorage.token,
      });

      if (result1?.errno === 0) {
        console.log(result1);
        store.commit('changeDatalist', { result1 });
        // sessionStorage.setItem("data", JSON.stringify(result1));
      } else {
        console.log("err", result1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getfleshstorge = async () => {
    try {
      const result = await post("fleshstorge", {
        token: sessionStorage.token,
      });

      if (result?.errno === 0) {
        console.log("getfleshstorge:", result);
        sessionStorage.setting = result?.list.setting;
        sessionStorage.theme1 = result?.list.theme1;
        sessionStorage.theme2 = result?.list.theme2;
        sessionStorage.theme3 = result?.list.theme3;
        sessionStorage.wifi= result?.list.wifi;

        const setinitData = {
          setting: result?.list.setting,
          theme1: result?.list.theme1,
          theme2: result?.list.theme2,
          theme3: result?.list.theme3,
          wifi: result?.list.wifi,
        };
        store.commit('changeInitData', { setinitData });

      } else {
        console.log("err", result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  //点击弹出确认按钮 旧版本
  // const oninstall = (ver) => {
  //   Dialog.confirm({
  //     title: "confirms upgrade:",
  //     message: "version:" + ver,
  //     confirmButtonText: "CONFIRM",
  //     cancelButtonText: "CANCEL",
  //   })
  //     .then(() => {
  //       upClick(1);
  //       console.log("OK1");
  //     })
  //     .catch(() => {
  //       console.log("***");
  //     });
  // };

  const upClick = async (uplist,themetype) => {
    console.log("uplist:",uplist)
    try {
      const result = await post("upSelectTheme", {
        uplist: uplist,
        themetype:themetype,
        token: sessionStorage.token,
      });
      if (result?.errno === 0) {
        console.log(result);
      } else {
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return { upClick, getfleshstorge, getversionlist };
};

export default {
  name: "theme",
  components: { Header },

  setup() {
    const store = useStore();
    console.log('store:',store.state.dataList)
    const showconfirmtheme = ref(false);
    const showthememsg = ref(false);
    const showConnectTheme = ref(false);
    const selectUpTheme = ref('');
    const selectUpThemeType = ref('');
    const selectUpThemeVersion = ref('');
    
    // const data = JSON.parse(sessionStorage.getItem("data"));

    // const themelist = JSON.parse(sessionStorage.getItem("themelist"));

    // const inlist = reactive({
    //   setting: route.params.setting,
    //   theme1: route.params.theme1,
    //   theme2: route.params.theme2,
    //   theme3: route.params.theme3,
    // });

    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const { handleselect, actionstitle, getthemelist, tlist} =
      handlethemeEffect();
    const {  upClick, getfleshstorge, getversionlist } =
      installEffect();

    getfleshstorge();
    getversionlist();

    getthemelist(actionstitle);

    // const themelist = reactive({list:JSON.parse(sessionStorage.getItem("themelist"))});

    const showPopover = ref(false);
    const actions = [
      { text: "THEME1" },
      { text: "THEME2" },
      { text: "THEME3" },
    ];

    const reac = "race1";

    return {
      actions,
      showPopover,
      handleselect,
      show,
      showPopup,
      upClick,
      reac,
      actionstitle,
      getthemelist,
      tlist,
      getfleshstorge,
      getversionlist,
      showconfirmtheme,showthememsg,showConnectTheme,selectUpTheme,selectUpThemeType,selectUpThemeVersion
    };
  },
};
</script>
<style  lang="scss" scoped>
@import "../../style/750/theme.scss";
.wrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block1 {
  width: 640px;
  height: 701px;
  background: #ffffff;
  border: 2px solid #707070;
  opacity: 1;
  border-radius: 82px;

  &__title {
    display: inline-block;
    margin-left: 200px;
    margin-top: 70px;
    width: 247px;
    height: 40px;
    font-size: 70px;
    font-family: BebasNeue;
    font-weight: 400;
    line-height: 0px;
    color: #161616;
    opacity: 1;
  }

  &__selectname {
    display: block;
    width: 640px;
    height: 166px;
    background: #808080;
    border: 2px solid #707070;
    opacity: 1;
    border-radius: 0px;
    &__content {
      display: inline-block;
      color: #ffffff;
      margin-left: 226px;
      margin-top: 42px;
      font-size: 70px;
    }
  }

  &__bottom {
    margin-top: 51px;

    &__magone {
      display: inline-block;
      margin-left: 81px;
      width: 475px;
      height: 50px;
      font-size: 70px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__magtwo {
      display: inline-block;
      margin-left: 37px;
      width: 561px;
      height: 40px;
      font-size: 44px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #f30000;
      opacity: 1;
    }

    &__hr {
      width: 475px;
      height: 0px;
      border: 2px solid #707070;
      opacity: 1;
    }
    &__msg {
      width: 557px;
      height: 189px;
      font-size: 53px;
      font-family: BebasNeue;
      font-weight: 400;
      line-height: 0px;
      color: #161616;
      opacity: 1;

      &__magwait {
        display: inline-block;
        margin-left: 200px;
        margin-top: 30px;
        height: 30px;
      }
      &__magwait2 {
        display: inline-block;
        margin-left: 37px;
        width: 600px;
        margin-top: 30px;
        font-family: BebasNeue;
        height: 30px;
      }
      &__magwait3 {
        display: inline-block;
        height: 30px;
        margin-top: 30px;
        width: 100%;
        margin-left: 83px;
      }
    }
  }
}
</style>

<style  lang="scss" >
:root {
  --van-popover-action-width: 196px;
  --van-popover-action-height: 60px;
  --van-popover-action-font-size: 30px;
  // --van-toast-loading-icon-color:red;
}
</style>



